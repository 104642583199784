import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Modal, Text } from '@pancakeswap-libs/uikit'
import web3, { KAVA_CHAIN_ID } from '../../web3'

interface ChainErrorModalProps {
  onDismiss?: () => void
}

const ModalContent = styled.div`
  margin-bottom: 16px;
`

const ChainErrorModal: React.FC<ChainErrorModalProps> = ({ onDismiss }) => {
  const handleNetworkChange = () => {
    window.ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.numberToHex(KAVA_CHAIN_ID) }],
      })
      .catch((switchError) => {
        if (switchError.code === 4902) {
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{ chainId: '0x8ae', chainName: 'Kava EVM Co-Chain', rpcUrls: ['https://evm.kava.io'] }],
          })
        }
      })
  }

  useEffect(() => {
    handleNetworkChange()
  }, [])

  return (
    <Modal title="Connection Error" onDismiss={onDismiss}>
      <ModalContent>
        <Text>Kindly switch to KAVA Network.</Text>
      </ModalContent>
    </Modal>
  )
}

export default ChainErrorModal
